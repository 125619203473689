import React, { useContext } from "react"
import { graphql } from "gatsby"
import ArticleLayout from "../layouts/ArticleLayout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import DatoCmsArticleContent from "../components/DatoCmsArticleContent"
import PageWrapperContext from "../context/PageWrapperContext"
import { BrandsSectionContent } from "./article"

export default ({ data: { article, labels }, pageContext: { locale } }) => {
  const { setHeaderTheme } = useContext(PageWrapperContext)
  setHeaderTheme(PageWrapperContext.default.theme)
  return (
    <ArticleLayout
      locale={locale}
      tags={article.categories}
      tagsLabel={labels.newsTags}
      brandsSectionContent={
        <BrandsSectionContent {...article} labels={labels} />
      }
      writtenByLabel={labels.newsWrittenBy}
      {...article}
      content={article.content.map(c => ({
        ...c,
        node: <DatoCmsArticleContent {...c} />,
        breakGrid: c.__typename === "DatoCmsSlideshow",
      }))}
    >
      <HelmetDatoCms seo={article.seoMetaTags} />
    </ArticleLayout>
  )
}

export const query = graphql`
  query($id: String!, $locale: String!) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      newsWrittenBy
      newsTags
      forThisProjectWeHaveUsed: forThisNewsArticleWeHaveUsed
      thisProjectContributedToUnsdg: thisNewsArticleContributedToUnsdg
    }
    article: datoCmsNewsItem(id: { eq: $id }, locale: { eq: $locale }) {
      title
      actualPublishDate
      author {
        __typename
        ... on DatoCmsExternal {
          email
          name
          isAvatarRound
          image {
            ...SmallAvatar
          }
        }
        ... on DatoCmsTeamMember {
          email
          isAvatarRound
          phoneNumber
          name
          image {
            ...SmallAvatar
          }
        }
      }
      content {
        ... on DatoCmsText {
          __typename
          id
          text
          textNode {
            internal {
              mediaType
            }
          }
        }
        ... on DatoCmsCallToActionBlock {
          ...CTABlockData
        }
        ... on DatoCmsImage {
          id
          captionVisible
          image {
            alt
            title
            fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsDownload {
          id
          file {
            url
            title
            filename
          }
        }
        ... on DatoCmsSlideshow {
          id
          images {
            fluid(
              maxWidth: 1440
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsAdditionalContent {
          id
          title
          text
          textNode {
            internal {
              mediaType
            }
          }
        }
      }
      brands {
        title
      }
      categories {
        ... on DatoCmsArticleCategory {
          locale
          name
          excerpt
          tagType
          originalId
        }
        ... on DatoCmsBrand {
          id
          title
          shortName
          originalId
        }
      }
      sdg {
        title
        icon {
          url
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
